.ant-alert {
  margin-bottom: 24px;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.ant-table-thead .ant-table-cell {
  font-weight: 700;
}

.ant-form-item-label label {
  white-space: normal;
}

.nl-card-dark {
  background-color: #001529;
  border-color: #4a4a4a;
  transition: all 0.2s;
}

.nl-card-dark * {
  color: rgba(255, 255, 255, 0.65) !important;
}

.nl-card-dark .ant-card-actions {
  background-color: #001529;
  border-top: 1px solid #4a4a4a;
}

.nl-card-dark .ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #4a4a4a;
}

.nl-card-dark .ant-switch {
  background-color: rgb(24 144 255);
}

.nl-card-dark .ant-avatar-image {
  background-color: #ccc;
}

.nl-box {
  background-color: white;
  padding: 20px;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
  margin: 0;
}

.ant-table-footer {
  padding: 0;
}

.ant-input-search .ant-input-affix-wrapper,
.ant-input-search .ant-input {
  padding: 4.3px 11px;
}

.ant-editor .ant-form-item-control-input {
  min-height: 0;
}

.ant-pagination-rtl .ant-pagination-total-text {
  margin-left: auto;
}

.ant-select-selection-item-content .ant-tag {
  display: block;
}

.ant-ltr-field {
  direction: ltr;
  text-align: right;
}

.ant-form-rtl .ant-form-item-label {
  text-align: right;
}

.ant-layout-rtl .react-tel-input .selected-flag {
  padding: 0 8px 0 0;
}

.ant-layout-rtl .react-tel-input .selected-flag .arrow {
  left: initial;
  right: 30px;
}

.ant-layout-rtl .react-tel-input .country-list .flag {
  margin-right: 0;
  margin-left: 7px;
}

.ant-pagination-line {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.ant-pagination-line .ant-pagination-total-text {
  width: 100%;
  text-align: center;
}

[dir='rtl'] .react-tel-input .flag-dropdown {
  border-radius: 0 3px 3px 0;
}

[dir='rtl'] .react-tel-input .selected-flag {
  padding: 0 8px 0 0;
  border-radius: 0 3px 3px 0;
}

[dir='rtl'] .react-tel-input .flag-dropdown.open {
  border-radius: 0 3px 0 0;
}

[dir='rtl'] .react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 0 3px 0 0;
}
.custom-box {
  background-color: white;
  padding: 20px;
}
.ant-table-row {
  cursor: pointer;
  height: 140px;
}

.custom-title {
  font-weight: 600;
  /* color: #0c4f95; */
}
.ant-table-cell {
  font-size: 14px;
  text-align: center;
}
th.ant-table-cell {
  text-align: center !important;
  font-size: 18px;
}
.custom-box-emails .ant-table-row {
  height: auto;
  padding: 30px 16px;
}

.custom-box-emails th.ant-table-cell {
  text-align: start !important;
}
